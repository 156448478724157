/* General Styles */
body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
  overflow-y: auto;
}

.container {
  display: flex;
  height: 100vh;
  overflow: hidden; /* Hide overflow on the container */
}

/* Form Container Styles */
.form-container {
  padding: 20px;
  background-color: #fff;
  overflow-y: auto; /* Add vertical scrollbar */

  padding: 20px;
  background-color: #fff;
  overflow-y: auto;
  min-width: 400px;
  max-width: 800px;
  width: 50%;
  /* background: green; */
  margin-left: auto;
  margin-right: auto;
}

.section {
  margin-bottom: 20px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  padding: 10px 20px;
  border-radius: 5px;
}

h2 {
  color: #333;
}

.form-group {
  margin-bottom: 10px;
}

label {
  font-weight: lighter;
  margin-bottom: 10px;
  display: inline-block;
}

input[type="number"],
select {
  box-sizing: border-box;
  width: calc(100% - 22px);
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

button[type="submit"] {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button[type="submit"]:hover {
  background-color: #0056b3;
}

button:disabled,
button[disabled]{
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

button:disabled:hover,
button[disabled]:hover{
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}


/* Preview Container Styles */
.preview-container {
  flex: 1;
  background-color: #f0f0f0;
  padding: 20px;
  overflow-y: auto; /* Add vertical scrollbar */
}

.preview {
  text-align: center;
}

.wall {
  width: 200px;
  height: 200px;
  background-color: white;
  border: 1px solid #ccc;
}

h2{
  font-weight: lighter;
}

.group{
  display: flex;
  display: flex;
    justify-content: space-between;
}

.group > div{
  width: 100%;
}

.form-group{
  margin-bottom: 10px;
    display: flex;
    flex-direction: column;
}

.buttonloader{
  display: flex;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  gap: 20px;
  
}